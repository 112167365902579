import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

import { colors } from "css/theme"
import { h2 } from "css/primitives"
import { routes, PGP_KEY_URL } from "src/constants"

import LinksGroup from "./LinksGroup"

const content = {
  sales: {
    title: "Sales Inquiries",
    links: [
      {
        title: "North America",
        text: "us.sales@smokescreen.io",
        type: "email",
      },
      {
        title: "EMEA",
        text: "emea.sales@smokescreen.io",
        type: "email",
      },
      {
        title: "ASIA PACIFIC",
        text: "apac.sales@smokescreen.io",
        type: "email",
      },
      {
        title: "GENERAL SALES INQUIRIES",
        text: "sales@smokescreen.io",
        type: "email",
      },
    ],
  },
  support: {
    title: "Support",
    links: [
      {
        title: "CUSTOMERS",
        text: "support@smokescreen.io",
        type: "email",
      },
      {
        title: "PARTNERS",
        text: "partner-support@smokescreen.io",
        type: "email",
      },
    ],
  },
  careers: {
    title: "Careers",
    text: "careers@smokescreen.io",
    type: "email",
  },
  corporate: {
    title: "Corporate",
    text: "info@smokescreen.io",
    type: "email",
  },
  security: {
    title: "Report Security Issues",
    links: [
      {
        text: "security@smokescreen.io",
        type: "email",
      },
      {
        text: "Responsible disclosure policy",
        type: "internal",
        link: routes.responsible_disclosure_policy,
      },
      {
        text: "Our PGP Key",
        type: "external",
        link: PGP_KEY_URL,
      },
    ],
  },
}

const wrapper = css`
  width: 50%;
  padding-left: 3rem;
  padding-top: 3rem;
  @media (max-width: 1205px) {
    padding-left: 0;
    max-width: 600px;
    width: 100%;
    padding-left: 1rem;
  }
  @media (max-width: 599px) {
    padding-top: 1.5rem;
  }
`

const heading = [
  h2,
  css`
    font-size: 28px;
    line-height: 2;
    margin-bottom: 0;
  `,
]

const groupLink = css`
  color: ${colors.brightBlue};
  font-size: 1rem;
  line-height: 2;
  text-decoration: none;
  margin-bottom: 1.5rem;
  display: inline-block;
  &:hover {
    text-decoration: underline;
  }
`

const groups = css`
  display: flex;
  flex-wrap: wrap;
`

const group = css`
  width: 50%;
  padding-right: 1rem;
  @media (max-width: 599px) {
    width: 100%;
  }
`

const dividedGroups = css`
  @media (max-width: 599px) {
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem;
  }
`

const dividedGroupLink = [
  groupLink,
  css`
    &::after {
      content: "|";
      padding-left: 0.7rem;
      padding-right: 0.7rem;
    }
    &:last-child::after {
      content: none;
    }
    @media (max-width: 599px) {
      line-height: 1.3;
      &::after {
        content: none;
      }
    }
  `,
]

const partnerDetails = css`
  color: #b2bbbd;
  a {
    color: ${colors.brightBlue};
  }
`

function Info() {
  return (
    <div css={wrapper}>
      <LinksGroup data={content.sales} />
      <div>
        <h2 css={heading}>Partners</h2>
        <p css={partnerDetails}>
          If you'd like to partner with us, fill out the contact form and select{" "}
          <em>"Are You A Distributor/Reseller/MSS Partner?"</em>.<br />
          You can also <Link to={routes.register_deal}>register a deal</Link> or
          write to us at{" "}
          <a href="mailto:dealregistration@smokescreen.io">
            dealregistration@smokescreen.io
          </a>
        </p>
      </div>
      <LinksGroup data={content.support} />
      <div css={groups}>
        <div css={group}>
          <h2 css={heading}>{content.careers.title}</h2>
          <a href={`mailto:${content.careers.text}`} css={groupLink}>
            {content.careers.text}
          </a>
        </div>
        <div css={group}>
          <h2 css={heading}>{content.corporate.title}</h2>
          <a href={`mailto:${content.corporate.text}`} css={groupLink}>
            {content.corporate.text}
          </a>
        </div>
      </div>
      <div>
        <h2 css={heading}>{content.security.title}</h2>
        <div css={dividedGroups}>
          {content.security.links.map((obj, index) => {
            if (obj.type === "internal") {
              return (
                <Link to={obj.link} css={dividedGroupLink} key={index}>
                  {obj.text}
                </Link>
              )
            }
            return (
              <a
                href={obj.type === "email" ? `mailto:${obj.text}` : obj.link}
                css={dividedGroupLink}
                key={index}
              >
                {obj.text}
              </a>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Info
