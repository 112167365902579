import React from "react"
import { css } from "@emotion/core"

import { colors } from "css/theme"
import { h2 } from "css/primitives"

const heading = [
  h2,
  css`
    font-size: 28px;
    line-height: 2;
    margin-bottom: 1rem;
  `,
]

const groups = css`
  display: flex;
  flex-wrap: wrap;
`

const group = css`
  width: 50%;
  padding-right: 1rem;
  @media (max-width: 599px) {
    width: 100%;
  }
`

const groupTitle = css`
  color: ${colors.gray};
  font-size: 12px;
  font-family: var(--heading);
  letter-spacing: 0.67px;
  text-transform: uppercase;
  margin-bottom: 2px;
`

const groupLink = css`
  color: ${colors.brightBlue};
  font-size: 1rem;
  line-height: 2;
  text-decoration: none;
  margin-bottom: 1.5rem;
  display: inline-block;
  &:hover {
    text-decoration: underline;
    color: ${colors.brightBlue};
  }
`

function LinksGroup({ data }) {
  if (!data) {
    return null
  }
  return (
    <div>
      <h2 css={heading}>{data.title}</h2>
      <div css={groups}>
        {data.links.map((obj, index) => {
          return (
            <div css={group} key={index}>
              <div css={groupTitle}>{obj.title}</div>
              <a
                href={obj.type === "email" ? `mailto:${obj.text}` : obj.link}
                css={groupLink}
              >
                {obj.text}
              </a>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default LinksGroup
