import React from "react"

import PageLayout from "../components/PageLayout"
import SEO from "../components/seo"
import { CompanyStructuredData } from "../components/structured-data"

import ogImage from "images/seo/contact.jpeg"

import Contact from "../views/contact"

const ContactPage = () => (
  <div>
    <SEO
      title="Contact"
      description="Have any questions? Need a hand? We’re here to help. Our support team is online throughout the day. Start a live chat now."
      image={ogImage}
    />
    <CompanyStructuredData />
    <PageLayout options={{ headingBackground: false }}>
      <Contact />
    </PageLayout>
  </div>
)

export default ContactPage
