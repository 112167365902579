import React from "react"
import { css } from "@emotion/core"

import { colors } from "css/theme"

import { Top, Form, Info } from "./components"

const pricing = css`
  background-color: ${colors.darkBackground};
  padding-bottom: 100px;
  overflow: hidden;
  margin-top: -90px;
  @media (max-width: 479px) {
    padding-bottom: 75px;
  }
`

const pricingInner = css`
  max-width: 1250px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  align-items: flex-start;
  @media (max-width: 1205px) {
    flex-direction: column;
    align-items: center;
  }
`

function Contact() {
  return (
    <div css={pricing}>
      <div>
        <Top />
        <div css={pricingInner}>
          <Form />
          <Info />
        </div>
      </div>
    </div>
  )
}

export default Contact
